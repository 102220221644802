<template>
    <!-- modal vertical center -->
    <b-modal :id="MODAL_NAME" :ref="MODAL_NAME" centered title="BUSCADOR CUM" ok-only ok-title="Cerrar" size="xl">
        <b-card-text>
            <div no-body class="mb-0">
                <div class="m-2">
                    <b-row>
                        <b-col cols="12" md="3">
                            <label>Mostrar</label>
                            <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
                                class="per-page-selector d-inline-block mx-50" />
                            <label>registros</label>
                        </b-col>
                        <b-col cols="12" md="4" align="right">
                            <b-input-group label-for="search">
                                <b-form-input id="search" trim v-model="tableSettings.searchQuery" placeholder="Busqueda" />
                                <b-input-group-append>
                                    <b-button variant="primary" class="btn-icon" @click="search">
                                        <feather-icon icon="SearchIcon" /> Buscar
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                    </b-row>
                </div>
                <b-table ref="cumsTable" primary-key="id" select-mode="single" class="position-relative"
                    empty-text="No se encontraron datos" hover bordered selectable responsive show-empty sticky-header
                    :fields="tableColumns" :busy="tableSettings.busy" :items="dataTable"
                    :sort-by.sync="tableSettings.sortBy" :sort-desc.sync="tableSettings.sortDesc"
                    @row-selected="onRowSelected" @row-clicked="onRowClicked"></b-table>

                <b-container class="mb-5">
                    <b-row>
                        <b-col cols="12" sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start">
                            <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of
                            }} registros</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col cols="12" sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end">
                            <b-pagination v-model="tableSettings.page" :total-rows="totalRows"
                                :per-page="tableSettings.perPage" first-number last-number class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item" next-class="next-item">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </b-card-text>
    </b-modal>
</template>

<script>
import { VBModal } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
    name: 'CumsSearch',
    props: {
        pharmacy: Object
    },
    data() {
        return {
            MODAL_NAME: 'modal-cums-table',
            selectedCum: [],
            perPageOptions: [10, 25, 50, 100],
            dataTable: [],
            dataMeta: {
                from: 0,
                to: 0,
                of: 0,
            },
            totalRows: 0,
            isSortDirDesc: true,
            tableColumns: [
                { key: 'id', label: 'ID', sortable: false },
                { key: 'cum', label: 'cum', sortable: false },
                { key: 'producto', label: 'Producto', sortable: false },
                { key: 'descripcioncomercial', label: 'Descripción comercial', sortable: false },
                { key: 'principioactivo', label: 'Principio activo', sortable: false },
            ],
            tableSettings: {
                searchQuery: '',
                perPage: 10,
                page: 1,
                sortBy: null,
                sortDesc: false,
                busy: false
            },
        }
    },
    components: {
        vSelect,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    methods: {
        onRowSelected(item) {
            this.$emit('searchCums', item)
            this.$refs[this.MODAL_NAME].hide()
        },
        onRowClicked(item) {
            this.$emit('searchCums', item)
            this.$refs[this.MODAL_NAME].hide()
        },
        listAll() {
            this.tableSettings.searchQuery = null;
            this.getTableData();
        },
        search() {
            this.getTableData();
        },
        async getTableData() {
            this.tableSettings.busy = true;
            this.pharmacy.searchQuery = this.tableSettings.searchQuery;
            await this.$http.post('/search-cums-for-pharmacy', this.pharmacy).then((response) => {
                this.dataTable = response.data.resultCums
                this.totalRows = response.data.total
                this.dataMetaCounter()
            })
            this.tableSettings.busy = false;
        },
        dataMetaCounter() {
            const localItemsCount = this.dataTable.length
            this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
            this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
            this.dataMeta.of = this.totalRows
        },
        showModal() {
            this.selectedCum = [];
            this.$refs[this.MODAL_NAME].show()
        },
        hideModal() {
            this.$refs[this.MODAL_NAME].hide()
        },
        onRowSelected(item) {
            this.selectedCum = item
            this.$emit('selectedCumTable', item)
        },
    },
    watch: {
        "tableSettings.sortBy": {
            handler(val) {
                this.getTableData()
            },
        },
        "tableSettings.sortDesc": {
            handler(val) {
                this.getTableData()
            },
        },
        "tableSettings.perPage": {
            handler(val) {
                this.getTableData()
            },
        },
        "tableSettings.page": {
            handler(val) {
                this.getTableData()
            },
        },
    },
}
</script>
